import React from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import { Link, useHistory } from "react-router-dom";
import { IfAnyGranted } from "react-authorization";

import { useDispatch } from "react-redux";

// import de Icons do menu

import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import BusinessIcon from "@material-ui/icons/Business";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import DvrOutlinedIcon from "@material-ui/icons/DvrOutlined";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import DoneIcon from "@material-ui/icons/DoneOutline";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import { RetrieveUserInformation } from "services/Auth/usermanager.service";

import "./styles.scss";
import CustomTooltip from "components/CustomTooltip";

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 30,
  },
}));

const NavBar = () => {
  const dispatch = useDispatch();
  const { retract } = useSelector((state) => state.stateMenu);
  const history = useHistory();
  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem("userinfo"));

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("apiVersion");
    localStorage.removeItem("userinfo");

    history.push("/");

    /*
    No arquivo 'configureStore' é desenvolvida a 
    lógica pra esse dispatch 'LOGOUT'
    */
    dispatch({
      type: "LOGOUT",
    });
  };

  return (
    <Col className="dvContainer p-0">
      <CssBaseline />

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: !retract,
          [classes.drawerClose]: retract,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: !retract,
            [classes.drawerClose]: retract,
          }),
        }}
      >
        <List className="p-0">
          <IfAnyGranted
            expected={[
              "Consumer",
              "Administrator",
              "CompanyAdministrator",
              "Analyst",
              "Guest",
            ]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link to="/dashboards">
              <CustomTooltip title={"Home"}>
                <MenuItem className="dvMenu">
                  <HomeOutlinedIcon
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  Home
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>

          <IfAnyGranted
            expected={[
              "Consumer",
              "Administrator",
              "CompanyAdministrator",
              "Operational",
              "Commercial",
              "Financial",
              "Guest",
              "Analyst",
              "CompanyFinancial",
            ]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link
              to={{
                pathname: `/user-details/${RetrieveUserInformation()._id}`,
                // state: { view: true },
              }}
            >
              <CustomTooltip title={"Meus dados"}>
                <MenuItem className="dvMenu">
                  <PersonOutlineIcon
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  Meus dados
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>
          {RetrieveUserInformation().chatAccess === true ?
            (<IfAnyGranted
              expected={["Operational", "Commercial", "Financial"]}
              actual={[...RetrieveUserInformation().roles]}
            >
              <Link to="/chat">
                <CustomTooltip title={"Chat"}>
                  <MenuItem className="dvMenu">
                    <ChatBubbleOutlineOutlinedIcon
                      style={{ marginRight: 8, cursor: "pointer" }}
                    />
                    Chat
                  </MenuItem>
                </CustomTooltip>
              </Link>
            </IfAnyGranted>) : null}

          <IfAnyGranted
            expected={["Administrator", "CompanyAdministrator", "Analyst"]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link
              to={
                RetrieveUserInformation().roles.includes("Administrator")
                  ? "/companies"
                  : `/company-details/${user.employer._id}`
              }
            >
              <CustomTooltip
                title={
                  RetrieveUserInformation().roles.includes("Administrator")
                    ? "Empresas"
                    : "Minha empresa"
                }
              >
                <MenuItem className="dvMenu">
                  <BusinessIcon style={{ marginRight: 8, cursor: "pointer" }} />
                  {RetrieveUserInformation().roles.includes("Administrator")
                    ? "Empresas"
                    : "Minha empresa"}
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>

          <IfAnyGranted
            expected={[
              "Consumer",
              "Administrator",
              "CompanyAdministrator",
              "Operational",
              "Analyst",
            ]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link to="/orders-list">
              <CustomTooltip title={"Pedidos pendentes"}>
                <MenuItem className="dvMenu">
                  <ReceiptOutlinedIcon
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  Pedidos pendentes
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>
          <IfAnyGranted
            expected={["Administrator", "CompanyAdministrator"]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link to="/orders-history">
              <CustomTooltip title={"Histórico de Pedidos"}>
                <MenuItem className="dvMenu">
                  <HistoryOutlinedIcon
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  Histórico de Pedidos
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>

          <IfAnyGranted
            expected={[
              "Administrator",
              "CompanyAdministrator",
              "CompanyFinancial",
              "Analyst",
            ]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link to="/financial">
              <CustomTooltip title={"Financeiro"}>
                <MenuItem className="dvMenu">
                  <AttachMoneyIcon
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  Financeiro
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>

          <IfAnyGranted
            expected={[
              "Consumer",
              "Administrator",
              "CompanyAdministrator",
              "Operational",
              "Analyst",
            ]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link to="/liberacao-online">
              <CustomTooltip title={"Liberação Online"}>
                <MenuItem className="dvMenu">
                  <DoneIcon style={{ marginRight: 8, cursor: "pointer" }} />
                  Liberação online
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>
          <IfAnyGranted
            expected={[
              "Consumer",
              "Administrator",
              "CompanyAdministrator",
              "Guest",
              "Analyst",
            ]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link to="/done-cargos">
              <CustomTooltip title={"Cargas Concluídas"}>
                <MenuItem className="dvMenu">
                  <DvrOutlinedIcon
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  Cargas Concluídas
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>
          <IfAnyGranted
            expected={[
              "Consumer",
              "Administrator",
              "CompanyAdministrator",
              "Guest",
              "Analyst",
            ]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link to="/archived-cargos">
              <CustomTooltip title={"Cargas arquivadas"}>
                <MenuItem className="dvMenu">
                  <HistoryOutlinedIcon
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  Cargas arquivadas
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>
          <IfAnyGranted
            expected={[
              "Consumer",
              "Administrator",
              "CompanyAdministrator",
              "Guest",
              "Analyst",
            ]}
            actual={[...RetrieveUserInformation().roles]}
          >
            <Link to="/settings">
              <CustomTooltip title={"Configurações"}>
                <MenuItem className="dvMenu">
                  <SettingsOutlinedIcon
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  Configurações
                </MenuItem>
              </CustomTooltip>
            </Link>
          </IfAnyGranted>

          <CustomTooltip title={"Sair"}>
            <MenuItem onClick={handleLogout} className="dvMenu">
              <ExitToAppOutlinedIcon
                style={{ marginRight: 8, cursor: "pointer" }}
              />
              Sair
            </MenuItem>
          </CustomTooltip>
        </List>
      </Drawer>
    </Col>
  );
};

export default NavBar;
